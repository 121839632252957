<template>
  <v-app class="px-10 detail-container" fluid>
    <v-popup-loading :isLoading="this.$store.state.customer.loading" />
    <!-- Breadcrumbs -->
    <v-row>
      <v-col class="pb-0">
        <v-breadcrumbs
          :items="linkroute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            Customer Information
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            t-data="delete-btn"
            id="remove-customer-btn"
            v-if="hasPermDelete() && this.$store.state.customer.is_delete === 0"
            class="remove-account-button button-bold"
            color="danger500Color"
            @click="handleDelete"
          >
            <v-icon t-data="delete-btn-icon" left light>
              mdi-trash-can-outline
            </v-icon>
            delete account
          </v-btn>
          <v-btn class="ma-2 button-bold" color="whiteColor" t-data="cancel-btn" @click="cancel">
            Cancel
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-breadcrumbs :items="linkroute" divider=">"></v-breadcrumbs> -->
    <v-diglog-confirm
      :show="isDelete"
      :subTitle="deleteSubTitle"
      :close="closeDeletePopup"
      :buttonText="deleteButtonConfirmText"
      :confirm="deleteCus"
    />
    <v-alert-error
      :show="deleteError"
      :close="closeDeleteErrorAlert"
      :msg="deleteErrorMsg"
    ></v-alert-error>
    <v-alert-error
      :show="removableError"
      :close="closeRemovableErrorAlert"
      :msg="removable.error"
    >
      <template v-slot:header>
        <div class="one-line">
          <h4 class="danger800Color--text">Failed
            <v-icon color="danger600Color">mdi-close-circle</v-icon>
          </h4>
        </div>
      </template>
    </v-alert-error>
    <div>
      <v-snackbar t-data="snackbar-success" v-model="isSuccess" width="500" color="success200Color" top centered>
        <div class="d-flex flex-row align-center mt-0">
          <v-img
            src="/images/snackbar-success.png"
            aspect-ratio="1"
            width="24"
            height="24"
            class="mt-0 mr-2"
            t-data="snackbar-success-image"
          ></v-img>
          <p class="mb-0 snackbar-message sub-body-regular">
            Customer information has been edited successfully.
          </p>
        </div>
      </v-snackbar>
      <v-row align="start" justify="start" class="mt-0">
        <v-col cols="3" class="mt-0 pt-0">
          <v-card t-data="profile-card">
            <v-card-text>
              <v-col align="center" justify="center">
                <v-img
                  t-data="profile-image"
                  v-bind:src="custimg ? custimg : '/images/default-profile.png'"
                  aspect-ratio="1"
                  class="grey lighten-2 rounded-circle mb-4"
                  max-width="100"
                  max-height="100"
                ></v-img>
                <h5
                  class="font-weight-medium mb-0 customer-information-name"
                  t-data="profile-name"
                >
                  {{
                    this.$store.state.customer.first_name
                      ? `${this.$store.state.customer.first_name} ${this.$store.state.customer.last_name}`
                      : ''
                  }}
                </h5>
                <p class="customer-information-gender body-regular" t-data="profile-gender">
                  {{ sex }}
                </p>
                <v-row align="start" justify="space-between">
                  <v-col align="start">
                    <p
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Birthdate
                    </p>
                    <p class="body-2 mb-0 information-text body-regular" t-data="profile-value">
                      {{ birth_date }}
                    </p>
                  </v-col>
                  <v-col align="end">
                    <p
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Nationality
                    </p>
                    <p class="body-2 mb-0 information-text body-regular" t-data="profile-value">
                      {{
                        this.$store.state.customer.nationality.trim()
                          ? this.$store.state.customer.nationality.trim()
                          : ''
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
            <v-row class="mt-0"><v-divider></v-divider></v-row>
            <v-card-text class="mt-0 pt-0">
              <v-col class="mt-0 pt-0">
                <v-row
                  align="center"
                  justify="start"
                  class="mt-0 pt-0 flex-nowrap"
                >
                  <v-img
                    aspect-ratio="1"
                    class="information-icon"
                    max-height="32"
                    max-width="32"
                    src="/images/national-id-logo.png"
                    style="margin-right: 7px;"
                    t-data="profile-logo"
                  ></v-img>

                  <v-col>
                    <p
                      v-if="!isThaiNationality"
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Passport
                    </p>
                    <p
                      v-if="isThaiNationality"
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      National ID
                    </p>
                    <p class="body-2 mb-0 information-text body-regular pr-2" t-data="profile-value">
                      {{ id_card }}
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  align="center"
                  justify="start"
                  offset-md="1"
                  class="flex-nowrap"
                >
                  <v-img
                    aspect-ratio="1"
                    class="information-icon"
                    max-height="32"
                    max-width="32"
                    src="/images/mobile-logo.png"
                    style="margin-right: 7px;"
                    t-data="profile-logo"
                  ></v-img>
                  <v-col>
                    <p
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Mobile No.
                    </p>
                    <p class="body-2 mb-0 information-text body-regular pr-2" t-data="profile-value">
                      {{ phoneNumber }}
                    </p>
                  </v-col>
                </v-row>
                <v-row align="center" justify="start" class="flex-nowrap">
                  <v-img
                    aspect-ratio="1"
                    src="/images/email-logo.png"
                    style="margin-right: 7px;"
                    class="information-icon"
                    max-height="32"
                    max-width="32"
                    t-data="profile-logo"
                  ></v-img>
                  <v-col>
                    <p
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Email
                    </p>
                    <p class="body-2 mb-0 information-text body-regular pr-2" t-data="profile-value">
                      {{ email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row align="center" justify="start" class="flex-nowrap">
                  <v-img
                    aspect-ratio="1"
                    src="/images/address-logo.png"
                    style="margin-right: 7px;"
                    class="information-icon"
                    max-height="32"
                    max-width="32"
                    t-data="profile-logo"
                  ></v-img>
                  <v-col>
                    <p
                      class="body-2 mb-0 customer-information-label sub-body-bold"
                      t-data="profile-label"
                    >
                      Address
                    </p>
                    <p v-if="isLoadingSuccess" class="body-2 mb-0 information-text body-regular pr-2" t-data="profile-value">
                      {{ address }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions class="pb-4 pt-0 mt-0" v-if="hasEditPerm()">
              <v-btn
                id="edit-customer-btn"
                outlined
                block
                @click="editCustomerInformation"
                class="edit-account-button button-bold"
                t-data="edit-btn"
              >
                <v-icon t-data="edit-btn-icon" left light>
                  mdi-square-edit-outline
                </v-icon>
                EDIT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="9" class="mt-0 pt-0">
          <v-card t-data="info-card">
            <v-card-title class="mt-0 pt-0 px-0 pb-3">
              <h6 class="mb-0 card-title" t-data="info-title">
                Information
              </h6>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mixpanel-button button-bold"
                t-data="mixpanel-btn"
                v-bind:href="
                  `https://mixpanel.com/report/2099265/view/249441/profile#distinct_id=${this.$route.params.uid}`
                "
                target="_blank"
              >
                mixpanel
              </v-btn>
            </v-card-title>
            <v-row class="mt-0"><v-divider></v-divider></v-row>
            <v-card-text class="mt-0">
              <v-row>
                <v-col class="no-top-bottom-space information-label body-regular" cols="3" t-data="info-label">
                  <p>Status</p>
                </v-col>
                <v-col
                  class="no-top-bottom-space d-inline-flex information-text body-regular"
                  cols="9"
                  t-data="info-value"
                >
                  <customer-status-chip class="chip-width" v-if="isLoadingSuccess" :status="this.$store.state.customer.is_delete"></customer-status-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="no-top-bottom-space information-label body-regular" cols="3" t-data="info-label">
                  <p>Registered on</p>
                </v-col>
                <v-col class="no-top-bottom-space information-text body-regular" cols="9" t-data="info-value">
                  <p v-if="isLoadingSuccess">{{ register_on }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="no-top-bottom-space information-label body-regular" cols="3" t-data="info-label">
                  <p>Register device</p>
                </v-col>
                <v-col class="no-top-bottom-space information-text body-regular" cols="9" t-data="info-value">
                  <p v-if="isLoadingSuccess">
                    {{
                      registerDevice
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="no-top-bottom-space information-label body-regular" cols="3" t-data="info-label">
                  <p>T&C Version</p>
                </v-col>
                <v-col class="no-top-bottom-space information-text body-regular" cols="9" t-data="info-value">
                  <p v-if="isLoadingSuccess">{{ tnc_version }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card t-data="binding-card">
            <v-card-title class="mt-0 pt-0 px-0 pb-3">
              <h6 class="mb-0 card-title" t-data="binding-title">
                Binding Information
              </h6>
            </v-card-title>
            <div
              v-if="this.$store.state.customer.patients.length <= 0"
              class="mt-0 pt-0"
              t-data="no-binding-content"
            >
              <v-row class="mt-0"><v-divider></v-divider></v-row>
              <v-col align="center" justify="center" align-self="center">
                <v-img
                  aspect-ratio="1"
                  max-height="72"
                  max-width="69"
                  src="/images/user-no-binding.png"
                  style="margin-right: 7px;"
                  class="mb-2"
                  t-data="no-binding-image"
                ></v-img>
                <p class="customer-information-empty-label body-bold mb-2">
                  ไม่พบข้อมูลการเชื่อมต่อ
                </p>
                <p class="customer-information-empty-text sub-body-regular">
                  หากมีการเชื่อมต่อข้อมูลโรงพยาบาล<br />
                  ข้อมูลดังกล่าวจะถูกแสดงที่บริเวณนี้
                </p>
              </v-col>
            </div>
            <div
              v-if="this.$store.state.customer.patients.length > 0"
              class="mt-0"
            >
              <v-tabs v-model="tab" class="mt-0">
                <v-tab
                  v-for="item in this.$store.state.customer.patients"
                  :key="item.index"
                  class="mt-0 pt-0 body-bold"
                  t-data="tab-panel"
                  >{{ item.org_codename }}
                </v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-card-text>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="item in this.$store.state.customer.patients"
                    :key="item.index"
                  >
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="binding-label"
                      >
                        <p>Connected Status</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space d-inline-flex information-text body-regular"
                        cols="9"
                        t-data="binding-value"
                      >
                        <v-chip
                          v-show="item.connection_status === 'success'"
                          color="chip"
                          class="chip-width info800Color--text connected-status-tag body-regular"
                          >Connected</v-chip
                        >
                        <v-chip
                          v-show="item.connection_status !== 'success'"
                          color="failChip"
                          class="chip-width disableTextColor--text connected-status-tag body-regular"
                        >
                          {{ capitalizeFirstLetter(item.connection_status) }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="binding-label"
                      >
                        <p>Connected Date/Time</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="binding-value"
                      >
                        <p>{{ item.connect_date_time }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="binding-label"
                      >
                        <p>Hospital Number (HN)</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="binding-value"
                      >
                        <p>{{ item.hn }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="binding-label"
                      >
                        <p>Connected Hospital</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="binding-value"
                      >
                        <p>{{ item.connect_hospital_name }}</p>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </div>
          </v-card>

          <v-card t-data="membership-card">
            <v-card-title class="mt-0 pt-0 px-0 pb-3">
              <h6 class="mb-0 card-title" t-data="membership-title">
                Membership
              </h6>
            </v-card-title>
            <div
              v-if="this.$store.state.customer.members.length <= 0"
              class="mt-0 pt-0"
              t-data="no-membership-content"
            >
              <v-row class="mt-0"><v-divider></v-divider></v-row>
              <v-col align="center" justify="center" align-self="center">
                <v-img
                  aspect-ratio="1"
                  max-height="54"
                  max-width="80"
                  src="/images/user-no-membership.png"
                  style="margin-right: 7px;"
                  class="mb-2"
                  t-data="no-membership-image"
                ></v-img>
                <p class="customer-information-empty-label body-bold mb-2">
                  ไม่พบข้อมูลบัตร
                </p>
                <p class="customer-information-empty-text sub-body-regular">
                  ข้อมูลจะถูกแสดงหลังจากผู้ใช้งานสมัครบัตรสมาชิกแล้ว
                </p>
              </v-col>
            </div>
            <div
              v-if="this.$store.state.customer.members.length > 0"
              class="mt-0"
            >
              <v-tabs v-model="membershipTab" class="mt-0">
                <v-tab
                  v-for="item in this.$store.state.customer.members"
                  :key="item.index"
                  class="mt-0 pt-0 body-bold"
                  t-data="tab-panel"
                  >{{ item.membership_org }}</v-tab
                >
              </v-tabs>
              <v-divider></v-divider>
              <v-card-text>
                <v-tabs-items v-model="membershipTab">
                  <v-tab-item
                    v-for="item in this.$store.state.customer.members"
                    :key="item.index"
                  >
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="membership-label"
                      >
                        <p>Membership ID</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="membership-value"
                      >
                        <p>{{ item.membership_no }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="membership-label"
                      >
                        <p>Type</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="membership-value"
                      >
                        <p>{{ item.type }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="membership-label"
                      >
                        <p>Subscribed on</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="membership-value"
                      >
                        <p>{{ item.create_date }}</p>
                        <!-- <p>{{ item.issue_date }}</p> -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="membership-label"
                      >
                        <p>Expired on</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="membership-value"
                      >
                        <p>{{ item.expire_date }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="no-top-bottom-space information-label body-regular"
                        cols="3"
                        t-data="membership-label"
                      >
                        <p>T&C Version</p>
                      </v-col>
                      <v-col
                        class="no-top-bottom-space information-text body-regular"
                        cols="9"
                        t-data="membership-value"
                      >
                        <p>{{ item.tnc_version }}</p>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </div>
          </v-card>
          <created-detail
            v-if="true"
            :show-create="false"
            :is-portal-user="true"
            :data-object="updatedDetail"
          />
        </v-col>
      </v-row>
    </div>
    <edit-dialog :show="isEdit" @cancel="handleEditCancel" @success="handleEditSuccess"></edit-dialog>
  </v-app>
</template>

<script>
import CreatedDetail from '@/components/CreatedDetail'
import EditDialog from '@/components/user-detail/EditDialog'
import {mapActions, mapGetters, mapState} from 'vuex'
import Confirm from '../../components/AlertConfirm'
import AlertError from '../../components/AlertError'
import {
  convertDateBirthDate,
  convertDate,
  hasPermision,
  hasPermissionsByOrgs
} from '../../helper/helper'
import CustomerStatusChip from '@/components/customer/CustomerStatusChip'
import PopupLoading from '@/components/PopupLoading'

export default {
  components: {
    'v-alert-error': AlertError,
    'v-diglog-confirm': Confirm,
    'v-popup-loading': PopupLoading,
    CreatedDetail,
    CustomerStatusChip,
    EditDialog
  },
  data() {
    return {
      tab: 0,
      membershipTab: 0,
      isDelete: false,
      isEdit: false,
      isSuccess: false,
      deleteError: false,
      removableError: false,
      email: '',
      phoneNumber: '',
      deleteButtonConfirmText: 'Remove',
      deleteSubTitle:
        'Customer information will be removed. Are you sure you want to remove?',
      deleteErrorMsg:
        'Customer cannot delete right now. Please try again later.',

      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Customers',
          disabled: false,
          href: '/customers'
        },
        {
          text: 'Information',
          disabled: true,
          href: '/'
        }
      ]
    }
  },
  async mounted() {
    await this.fetch()
  },
  computed: {
    isLoadingSuccess: function() {
      return !this.$store.state.customer.loading
    },
    birth_date: function() {
      return this.$store.state.customer.birth_date &&
        this.$store.state.customer.birth_date !== '' &&
        this.$store.state.customer.birth_date !== '0000-00-00'
        ? convertDateBirthDate(this.$store.state.customer.birth_date)
        : ''
    },
    register_on: function() {
      return this.$store.state.customer.create_date != ''
        ? convertDate(this.$store.state.customer.create_date)
        : ''
    },
    registerDevice: function() {
      if (this.$store.state.customer.device_os) {
        return this.$store.state.customer.device_os === 'ios'
          ? this.$store.state.customer.device_os.toUpperCase()
          : this.capitalizeFirstLetter(this.$store.state.customer.device_os)
      } else {
        return ''
      }

    },
    tnc_version: function() {
      return this.$store.state.customer.tnc_version || ''
    },
    member_tnc_version: function() {
      return this.$store.state.customer.member.tnc_version || ''
    },
    binding_tnc_version: function() {
      return this.$store.state.customer.binding_tnc_version || ''
    },
    updatedDetail: function() {
      return {
        update_by_user: this.update_by_user,
        update_date: this.update_date
      }
    },
    sex: function() {
      switch (this.$store.state.customer.sex) {
        case 'M':
          return 'Male'
        case 'F':
          return 'Female'
        default:
          return this.$store.state.customer.sex || ''
      }
    },
    create_by_org: function() {
      return this.$store.state.customer.create_by_org || ''
    },
    update_by_org: function() {
      return this.$store.state.customer.update_by_org || ''
    },
    address: function() {
      let addressText = this.$store.state.customer.address
      if (this.$store.state.customer.sub_district.length > 0) {
        addressText += ` ${this.$store.state.customer.sub_district}`
      }
      if (this.$store.state.customer.district.length > 0) {
        addressText += ` ${this.$store.state.customer.district}`
      }
      if (this.$store.state.customer.province.length > 0) {
        addressText += ` ${this.$store.state.customer.province}`
      }
      if (this.$store.state.customer.postal.length > 0) {
        addressText += ` ${this.$store.state.customer.postal}`
      }
      return addressText.length > 0 ? addressText.trim() : '-'
    },
    issue_date: function() {
      if (this.$store.state.customer.member.id) {
        return this.$store.state.customer.member.create_date != ''
          ? convertDate(this.$store.state.customer.member.create_date)
          : ''
      } else {
        return ''
      }
    },
    expire_date: function() {
      if (this.$store.state.customer.member.id) {
        return this.$store.state.customer.member.member_ship.expire_date != ''
          ? convertDate(
              this.$store.state.customer.member.member_ship.expire_date
            )
          : ''
      } else {
        return ''
      }
    },
    member_no: function() {
      if (this.$store.state.customer.member.id) {
        return this.$store.state.customer.member.member_no
      } else {
        return ''
      }
    },
    hn: function() {
      return this.$store.state.customer.hn || ''
    },
    connect_status: function() {
      return this.$store.state.customer.connect_status || ''
    },
    connect_date_time: function() {
      return this.$store.state.customer.connect_date_time || ''
    },
    connect_hospital_name: function() {
      return this.$store.state.customer.connect_hospital_name || ''
    },
    custimg: function() {
      return this.$store.state.customer.profile === ''
        ? ''
        : this.$store.state.customer.profile
    },
    ...mapState('customer', ['nationality', 'id_card', 'removable', 'update_by_user', 'update_date']),
    ...mapGetters('customer', ['isThaiNationality'])
  },
  methods: {
    renderCustomerBinding() {
      return `<div class="text-center">
    <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Inset
        </v-btn>
      </template>
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="sheet = !sheet"
        >close</v-btn>
        <div class="my-3">This is a bottom sheet using the inset prop</div>
      </v-sheet>
    </v-bottom-sheet>
  </div>`
    },
    async fetch() {
      const payload = {
        uid: this.$route.params.uid
      }
      await this.fetchData(payload)
      this.email = this.$store.state.customer.email
      this.phoneNumber = this.$store.state.customer.phone_number
    },
    type(item) {
      return item.membership_contents[0].name
    },
    dateFormat(date) {
      return date ? convertDate(date) : ''
    },
    hasPermDelete() {
      if (hasPermision('CanDeleteAllCustomer')) {
        return true
      }

      const patients = this.$store.state.customer.patients || []
      const orgs = patients.map(org => {
        if (org.is_connect) {
          return org.org_codename
        }
      })
      if (patients.length <= 0) {
        return false
      } else if (
        hasPermissionsByOrgs('CanDeleteBindedCustomer', orgs) &&
        patients.length > 0
      ) {
        return true
      }

      return false
    },
    capitalizeFirstLetter(text) {
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
    },
    editCustomerInformation() {
      this.isEdit = true
    },
    cancel() {
      this.$router.push({name: 'user-list'})
    },
    async handleDelete() {
      const success = await this.checkRemovable(this.$route.params.uid)
      if (!success) {
        this.deleteError = true
      }
      if (this.removable.status) {
        this.isDelete = true
      } else {
        this.removableError = true
      }
    },
    closeDeletePopup() {
      this.isDelete = false
    },
    connectStatusColorClass: function(is_connect) {
      return is_connect ? 'green--text' : 'red--text'
    },
    async deleteCus() {
      const payload = {uid: this.$route.params.uid}
      const result = await this.deleteCustomer(payload)
      if (result) {
        this.$router.push('/customers')
      } else {
        this.isDelete = false
        this.deleteError = true
      }
    },
    closeDeleteErrorAlert() {
      this.deleteError = false
    },
    closeRemovableErrorAlert() {
      this.removableError = false
      this.$router.go()
    },
    hasEditPerm() {
      if (hasPermision('CanEditAllCustomer')) {
        return true
      }

      // *(note) check if binded
      const patients = this.$store.state.customer.patients || []
      const orgs = patients.map(org => {
        if (org.is_connect) {
          return org.org_codename
        }
      })
      if (patients.length <= 0) {
        return false
      } else if (
        hasPermissionsByOrgs('CanEditBindedCustomer', orgs) &&
        patients.length > 0
      ) {
        return true
      }

      return false
    },
    async handleEditCancel() {
      this.isEdit = false
      await this.fetch({
        uid: this.$route.params.uid
      })
    },
    async handleEditSuccess() {
      this.isEdit = false
      this.isSuccess = true
      await this.fetch({
        uid: this.$route.params.uid
      })
    },
    ...mapActions('customer', [
      'fetchData',
      'update',
      'setIdcard',
      'deleteCustomer',
      'checkRemovable'
    ])
  }
}
</script>

<style scoped>
.space {
  margin-top: 20px;
}

td,
th {
  text-align: left;
  padding: 5px;
}

span {
  font-size: 16px;
  min-width: 200px;
  min-height: 35px;
  display: block;
}

span.hover {
  background: grey;
  font-size: 16px;
  min-width: 200px;
  min-height: 35px;
  display: block;
}

div {
  margin-top: 10px;
}

div.hover {
  margin-top: 10px;
  background: var(--div-hover-bg-color);
  min-width: 200px;
  display: block;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.chip-width {
  min-width: 0px !important;
}

.customer-information-label {
  color: var(--primary-text-color) !important;
}

.customer-information-name {
  color: var(--header-text-color) !important;
}
.customer-information-gender {
  color: var(--secondary-text-color) !important;
}
.customer-information-empty-label {
  color: var(--primary-text-color) !important;
}
.customer-information-empty-text {
  color: var(--secondary-text-color) !important;
}
.remove-account-button {
  color: var(--primary-100-color) !important;
}
.status-tag {
  color: var(--info-800-color) !important;
}
.app-version-link {
  color: var(--info-500-color) !important;
}

.information-icon {
  height: 32px;
  width: 32px;
}

.error-icon {
  height: 16px !important;
  width: 16px !important;
}
.edit-account-button {
  border-color: var(--border-color) !important;
  color: var(--primary-text-color) !important;
  letter-spacing: 0.56px;
}
.mixpanel-button {
  border-color: var(--primary-400-color) !important;
  background: var(--primary-100-color) !important;
  color: var(--primary-700-color) !important;
  letter-spacing: 0.56px;
}

.edit-error-message {
  margin-top: 0px;
  color: var(--danger-600-color) !important;
}

</style>
