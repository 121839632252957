<template>
  <v-chip
    t-data="status-chip"
    :text-color="statusTextColor(status)"
    :color="statusFillColor(status)"
    dark
  >
    {{ statusName(status) }}
  </v-chip>
</template>

<script>
import {customerStatus, customerStatusEnum} from '@/constants/customer'

export default {
  name: 'CustomerStatusChip',
  props: {
    status: {type: Number}
  },
  methods: {
    statusTextColor(status) {
      if (status === customerStatusEnum.ACTIVE) return 'info800Color'
      if (status === customerStatusEnum.INACTIVE) return 'disableTextColor'
    },
    statusFillColor(status) {
      if (status === customerStatusEnum.ACTIVE) return 'info200Color'
      if (status === customerStatusEnum.INACTIVE) return 'failChip'
    },
    statusName(status) {
      return customerStatus[status]
    }
  }
}
</script>
