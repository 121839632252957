<template>
  <v-dialog v-model="show" width="360" t-data="edit-dialog" persistent>
    <v-card class="edit-container">
      <v-card-title class="edit-customer-title" t-data="edit-dialog-title">
        <h4>Edit Customer Information</h4>
      </v-card-title>

      <v-card-text class="pb-0 mb-5">
        <div class="mb-3">
          <p
            v-if="!isThaiNationality"
            class="edit-field-label sub-body-bold"
            t-data="edit-dialog-label"
          >
            Passport
          </p>
          <p
            v-if="isThaiNationality"
            class="edit-field-label sub-body-bold"
            t-data="edit-dialog-label"
          >
            National ID
          </p>
          <v-text-field
            t-data="edit-dialog-value"
            id="national-id-text-box"
            name="national-id-fields"
            v-model="form.id_card"
            outlined
            class="subtitle-2"
            hide-details
            :rules="[idCardRules.required, idCardRules.valid]"
            dense
          ></v-text-field>
          <v-row align="center" justify="start" v-show="isIdCardUpdateError">
            <v-img
              aspect-ratio="1"
              class="error-icon mt-0 mr-1 ml-4"
              max-height="16px"
              max-width="16px"
              src="/images/icons/error-icon.png"
            ></v-img>
            <p class="edit-error-message mb-0">
              {{ updateIdCardErrorMessage }}
            </p>
          </v-row>
        </div>

        <div class="mb-3">
          <p class="edit-field-label sub-body-bold" t-data="edit-dialog-label">
            Mobile No.
          </p>
          <v-text-field
            t-data="edit-dialog-value"
            id="mobile-number-text-box"
            name="mobile-number-fields"
            v-model="form.phone_number"
            outlined
            class="subtitle-2"
            hide-details
            :rules="[phoneRules.required, phoneRules.valid]"
            dense
          ></v-text-field>
          <v-row align="center" justify="start" v-show="isPhoneUpdateError">
            <v-img
              aspect-ratio="1"
              class="error-icon mt-0 mr-1 ml-4"
              max-height="16px"
              max-width="16px"
              src="/images/icons/error-icon.png"
            ></v-img>
            <p class="edit-error-message mb-0">
              {{ updatePhoneErrorMessage }}
            </p>
          </v-row>
        </div>

        <div class="mb-3">
          <p class="edit-field-label sub-body-bold" t-data="edit-dialog-label">
            Email
          </p>
          <v-text-field
            t-data="edit-dialog-value"
            id="email-text-box"
            name="email-fields"
            v-model="form.email"
            outlined
            class="subtitle-2"
            hide-details
            :rules="[emailRules.required, emailRules.valid]"
            dense
          ></v-text-field>
          <v-row align="center" justify="start" v-show="isEmailUpdateError">
            <v-img
              aspect-ratio="1"
              class="error-icon mt-0 mr-1 ml-4"
              max-height="16px"
              max-width="16px"
              src="/images/icons/error-icon.png"
            ></v-img>
            <p class="edit-error-message mb-0">
              {{ updateEmailErrorMessage }}
            </p>
          </v-row>
        </div>
        <div class="mb-3">
          <v-checkbox
            t-data="edit-dialog-value"
            color="secondary"
            v-model="form.flag_address"
            label="No address in Thailand"
            class="mt-0 filterContent body-regular"
            @click="clickFlagAddress"
            :false-value="true"
            :true-value="false"
            hide-details
          ></v-checkbox>
        </div>
        <template v-if="form.flag_address">
          <div class="mb-3">
            <p
              class="edit-field-label sub-body-bold"
              t-data="edit-dialog-label"
            >
              Address
            </p>
            <v-textarea
              t-data="edit-dialog-value"
              v-model="form.address"
              id="address-text-box"
              name="address-fields"
              outlined
              class="subtitle-2"
              height="100"
              hide-details
              dense
            ></v-textarea>
          </div>
          <div class="mb-3">
            <p
              class="edit-field-label sub-body-bold"
              t-data="edit-dialog-label"
            >
              Province
            </p>
            <v-autocomplete
              t-data="edit-dialog-value"
              outlined
              dense
              class="can-disable-selector subtitle-2"
              placeholder="Select Province"
              v-model="form.province_id"
              item-text="name"
              item-value="id"
              :items="provinces"
              @input="getDistricts"
              hide-details
            ></v-autocomplete>
          </div>
          <div class="mb-3">
            <p
              class="edit-field-label sub-body-bold"
              t-data="edit-dialog-label"
            >
              District
            </p>
            <v-autocomplete
              outlined
              dense
              class="can-disable-selector subtitle-2"
              t-data="edit-dialog-value"
              placeholder="Select District"
              v-model="form.district_id"
              item-text="name"
              item-value="id"
              :items="districts"
              :disabled="form.province_id <= 0"
              @input="getSubDistricts"
              hide-details
            ></v-autocomplete>
          </div>
          <div class="mb-3">
            <p
              class="edit-field-label sub-body-bold"
              t-data="edit-dialog-label"
            >
              Sub-district
            </p>
            <v-autocomplete
              outlined
              dense
              class="can-disable-selector subtitle-2"
              t-data="edit-dialog-value"
              placeholder="Select Sub-district"
              v-model="form.sub_district_id"
              item-text="name"
              item-value="id"
              :items="sub_districts"
              :disabled="form.district_id <= 0"
              @input="setPostal"
              hide-details
            >
              <template v-slot:item="{item}">
                <v-list-item-content class="primaryTextColor--text">
                  <v-list-item-title
                    >{{ item.name }} / {{ item.postal }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
          <div class="mb-3">
            <p
              class="edit-field-label sub-body-bold"
              t-data="edit-dialog-label"
            >
              Zipcode
            </p>
            <v-autocomplete
              outlined
              dense
              class="can-disable-selector subtitle-2"
              t-data="edit-dialog-value"
              placeholder="Select Zipcode"
              v-model="form.sub_district_id"
              item-value="id"
              item-text="postal"
              :items="sub_districts"
              :disabled="form.district_id <= 0"
              @input="setPostal"
              hide-details
            >
              <template v-slot:item="{item}">
                <v-list-item-content class="primaryTextColor--text">
                  <v-list-item-title
                    >{{ item.name }} / {{ item.postal }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </template>
        <v-row align="center" justify="start" v-show="isAddressUpdateError">
          <v-img
            aspect-ratio="1"
            class="error-icon mt-0 mr-1 ml-4"
            max-height="16px"
            max-width="16px"
            src="/images/icons/error-icon.png"
          ></v-img>
          <p class="edit-error-message mb-0">
            {{ updateAddressErrorMessage }}
          </p>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-end mt-0 pt-0">
        <v-btn
          id="edit-cancel-btn"
          color="info600Color"
          class="sub-body-bold"
          t-data="edit-cancel-btn"
          text
          @click="handleCancel"
        >
          CANCEL
        </v-btn>
        <v-btn
          id="edit-save-btn"
          :disabled="isSaveButtonDisable()"
          color="info600Color"
          class="sub-body-bold"
          t-data="edit-save-btn"
          text
          @click="updateCustomerDetails"
        >
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'EditDialog',
  props: {
    show: {type: Boolean},
  },
  mounted() {
    this.form = {
      id_card: this.id_card,
      phone_number: this.phone_number,
      email: this.email,
      flag_address: this.flag_address,
      address: this.address,
      province_id: this.province_id,
      district_id: this.district_id,
      sub_district_id: this.sub_district_id,
      postal: this.postal
    }
  },
  data() {
    return {
      form: {
        id_card: this.id_card,
        phone_number: this.phone_number,
        email: this.email,
        flag_address: this.flag_address,
        address: this.address,
        province_id: this.province_id,
        district_id: this.district_id,
        sub_district_id: this.sub_district_id,
        postal: this.postal
      },
      idCardRules: {
        required: value => {
          if (value) {
            this.isIdCardUpdateError = false
            this.updateIdCardErrorMessage = ''
            return true
          }
          this.updateIdCardErrorMessage = 'Please enter national ID.'
          this.isIdCardUpdateError = true
          return false
        },
        valid: value => {
          if (!value) {
            this.updateIdCardErrorMessage = 'Please enter national ID.'
            this.isIdCardUpdateError = true
            return false
          }

          if (!this.isThaiNationality) {
            this.isIdCardUpdateError = false
            this.updateIdCardErrorMessage = ''
            return true
          }
          if (value.length == 13) {
            this.isIdCardUpdateError = false
            this.updateIdCardErrorMessage = ''
            return true
          } else {
            this.updateIdCardErrorMessage = 'Invalid national ID format.'
            this.isIdCardUpdateError = true
            return false
          }
        }
      },
      phoneRules: {
        required: value => {
          if (value) {
            this.isPhoneUpdateError = false
            this.updatePhoneErrorMessage = ''
            return true
          }
          this.updatePhoneErrorMessage = 'Please enter mobile no.'
          this.isPhoneUpdateError = true
          return false
        },
        valid: value => {
          if (!value) {
            this.updatePhoneErrorMessage = 'Please enter mobile no.'
            this.isPhoneUpdateError = true
            return false
          }

          const pattern = /^\+[0-9]+$/
          if (pattern.test(value)) {
            this.isPhoneUpdateError = false
            this.updatePhoneErrorMessage = ''
            return true
          }
          this.updatePhoneErrorMessage = 'Invalid mobile no. format.'
          this.isPhoneUpdateError = true
          return false
        }
      },
      emailRules: {
        required: value => {
          if (value) {
            this.isEmailUpdateError = false
            this.updateEmailErrorMessage = ''
            return true
          }
          if (this.email === '') {
            this.isEmailUpdateError = false
            this.updateEmailErrorMessage = ''
            return true
          }
          this.updateEmailErrorMessage = 'Please enter email.'
          this.isEmailUpdateError = true
          return false
        },
        valid: value => {
          if (this.email === '') {
            this.isEmailUpdateError = false
            this.updateEmailErrorMessage = ''
            return true
          }

          if (!value) {
            this.updateEmailErrorMessage = 'Please enter email.'
            this.isEmailUpdateError = true
            return false
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (pattern.test(value)) {
            this.isEmailUpdateError = false
            this.updateEmailErrorMessage = ''
            return true
          }
          this.updateEmailErrorMessage = 'Invalid email format.'
          this.isEmailUpdateError = true
          return false
        }
      },
      isIdCardUpdateError: false,
      isPhoneUpdateError: false,
      isEmailUpdateError: false,
      isAddressUpdateError: false,
      updateIdCardErrorMessage: '',
      updatePhoneErrorMessage: '',
      updateEmailErrorMessage: '',
      updateAddressErrorMessage: ''
    }
  },

  watch: {
    id_card() {
      this.form.id_card = _.cloneDeep(this.id_card)
    },
    phone_number() {
      this.form.phone_number = _.cloneDeep(this.phone_number)
    },
    email() {
      this.form.email = _.cloneDeep(this.email)
    },
    flag_address() {
      this.form.flag_address = _.cloneDeep(this.flag_address)
    },
    address() {
      this.form.address = _.cloneDeep(this.address)
    },
    province_id() {
      this.form.province_id = _.cloneDeep(this.province_id)
    },
    district_id() {
      this.form.district_id = _.cloneDeep(this.district_id)
    },
    sub_district_id() {
      this.form.sub_district_id = _.cloneDeep(this.sub_district_id)
    },
    postal() {
      this.form.postal = _.cloneDeep(this.postal)
    }
  },
  computed: {
    ...mapState('customer', [
      'address',
      'district_id',
      'districts',
      'email',
      'flag_address',
      'id_card',
      'phone_number',
      'postal',
      'province_id',
      'provinces',
      'sub_district_id',
      'sub_districts'
    ]),
    ...mapGetters('customer', ['isThaiNationality'])
  },
  methods: {
    isSaveButtonDisable() {
      if (
        this.isEmailUpdateError ||
        this.isPhoneUpdateError ||
        this.isIdCardUpdateError ||
        this.isAddressUpdateError
      ) {
        return true
      }

      if (
        this.phone_number === this.form.phone_number &&
        this.id_card === this.form.id_card &&
        this.email === this.form.email &&
        this.flag_address === this.form.flag_address &&
        this.address === this.form.address &&
        this.province_id === this.form.province_id &&
        this.district_id === this.form.district_id &&
        this.sub_district_id === this.form.sub_district_id &&
        this.postal === this.form.postal
      ) {
        return true
      }

      return false
    },
    async updateCustomerDetails() {
      if (!this.form.flag_address) {
        this.resetAddress()
        this.resetProvinceId()
        this.resetDistrictId()
        this.resetSubDistrictId()
        this.resetPostal()
      }

      if (this.phone_number !== this.form.phone_number) {
        await this.updatePhone()
      }
      if (this.id_card !== this.form.id_card) {
        await this.updateIdCard()
      }
      if (this.email !== this.form.email) {
        await this.updateEmail()
      }
      if (
        this.flag_address !== this.form.flag_address ||
        this.address !== this.form.address ||
        this.province_id !== this.form.province_id ||
        this.district_id !== this.form.district_id ||
        this.sub_district_id !== this.form.sub_district_id ||
        this.postal !== this.form.postal
      ) {
        await this.handleUpdateAddress()
      }

      if (
        !this.isIdCardUpdateError &&
        !this.isPhoneUpdateError &&
        !this.isEmailUpdateError &&
        !this.isAddressUpdateError
      ) {
        this.isEdit = false
        this.$emit('success')
      }
    },
    async updatePhone() {
      let reg = new RegExp(/^\+[0-9]+$/)
      if (this.form.phone_number != '' && reg.test(this.form.phone_number)) {
        let payload = {
          uid: this.$route.params.uid,
          fieldName: 'phone_number',
          value: this.form.phone_number
        }
        let update = await this.update(payload)
        if (update.success) {
          this.isPhoneUpdateError = false
          this.updatePhoneErrorMessage = ''
        } else {
          this.isPhoneUpdateError = true
          this.updatePhoneErrorMessage = update.message
        }
      } else {
        this.isPhoneUpdateError = true
        if (this.form.phone_number === '') {
          this.updatePhoneErrorMessage = 'Mobile is required!!'
          return false
        }
        if (!reg.test(this.form.phone_number)) {
          this.updatePhoneErrorMessage = 'Wrong Mobile format!!'
          return false
        }
      }
    },
    async updateIdCard() {
      if (!this.isIdCardUpdateError) {
        let payload = {
          uid: this.$route.params.uid,
          fieldName: 'id_card',
          value: this.form.id_card
        }
        let update = await this.update(payload)
        if (update.success) {
          this.isIdCardUpdateError = false
          this.updateIdCardErrorMessage = ''
        } else {
          this.isIdCardUpdateError = true
          this.updateIdCardErrorMessage = update.message
        }
      }
    },
    async updateEmail() {
      if (this.form.email != '' && this.validateEmail(this.form.email)) {
        let payload = {
          uid: this.$route.params.uid,
          fieldName: 'email',
          value: this.form.email
        }
        let update = await this.update(payload)
        if (update.success) {
          this.isEmailUpdateError = false
          this.updateEmailErrorMessage = ''
        } else {
          this.isEmailUpdateError = true
          this.updateEmailErrorMessage = update.message
        }
      } else {
        this.isEmailUpdateError = true
        if (this.form.email === '') {
          this.updateEmailErrorMessage = 'Email is Require!!'
          return false
        }
        if (!this.validateEmail(this.form.email)) {
          this.updateEmailErrorMessage = 'Wrong Email format!!'
          return false
        }
      }
    },
    async handleUpdateAddress() {
      const payload = {
        uid: this.$route.params.uid,
        flag_address: this.form.flag_address,
        address: this.form.address,
        province_id: this.form.province_id,
        district_id: this.form.district_id,
        sub_district_id: this.form.sub_district_id,
        post: this.form.postal
      }
      const update = await this.updateAddress(payload)
      if (update.success) {
        this.isAddressUpdateError = false
        this.updateAddressErrorMessage = ''
      } else {
        this.isAddressUpdateError = true
        this.updateAddressErrorMessage = update.message
      }
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    async handleCancel() {
      this.resetForm()
      this.$emit('cancel')
    },
    resetForm() {
      this.form.id_card = _.cloneDeep(this.id_card)
      this.form.phone_number = _.cloneDeep(this.phone_number)
      this.form.email = _.cloneDeep(this.email)
      this.form.flag_address = _.cloneDeep(this.flag_address)
      this.form.address = _.cloneDeep(this.address)
      this.form.province_id = _.cloneDeep(this.province_id)
      this.form.district_id = _.cloneDeep(this.district_id)
      this.form.sub_district_id = _.cloneDeep(this.sub_district_id)
      this.form.postal = _.cloneDeep(this.postal)

      this.isIdCardUpdateError = false
      this.isPhoneUpdateError = false
      this.isEmailUpdateError = false
      this.isAddressUpdateError = false
      this.updateIdCardErrorMessage = ''
      this.updatePhoneErrorMessage = ''
      this.updateEmailErrorMessage = ''
      this.updateAddressErrorMessage = ''
    },
    async clickFlagAddress() {
      if (this.form.flag_address) await this.fetchProvinces()
    },
    async getDistricts(id) {
      this.resetDistrictId()
      this.resetSubDistrictId()
      this.resetPostal()
      await this.fetchDistricts(id)
    },
    async getSubDistricts(id) {
      this.resetSubDistrictId()
      this.resetPostal()
      await this.fetchSubDistricts(id)
    },
    setPostal(subDistrictsId) {
      const subDistrict = this.sub_districts.find(d => d.id === subDistrictsId)
      this.form.postal = subDistrict.postal
    },
    resetAddress() {
      this.form.address = ''
    },
    resetProvinceId() {
      this.form.province_id = 0
    },
    resetDistrictId() {
      this.form.district_id = 0
    },
    resetSubDistrictId() {
      this.form.sub_district_id = 0
    },
    resetPostal() {
      this.form.postal = ''
    },
    ...mapActions('customer', [
      'fetchDistricts',
      'fetchProvinces',
      'fetchSubDistricts',
      'update',
      'updateAddress',
    ])
  }
}
</script>
